import './styles.css';
import { useEffect, useRef, useState } from "react";
import Unity, { UnityContent} from "react-unity-webgl";
import {
  Link, useHistory
} from "react-router-dom";
import { Volume2, VolumeX, Settings } from 'react-feather';
import { LoadingScreen } from '../loading-screen';
const unityContent = new UnityContent(
  "../build/Build/webGLBuild.json",
  "../build/Build/UnityLoader.js"
)
const qualityValues = [5, 3, 0];
const qualityMap = {0: 'High', 1: 'Medium', 2:'Low'}
export function PlayPenPlayer({tangos, claim, updateState, mintFloor, approve, floors}) {
  const [isMuted, setIsMuted] = useState(true);
  const [isLoading, setIsLoading] = useState(true, []);
  const [qualityText, setQualityText] = useState(null);
  const [quality, setQuality] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isReadyForCustomization, setIsReadyForCustomization] = useState(false);
  const wrapRef = useRef(null);
  const [mintingFloor, setMintingFloor]= useState(false);
  const [tokensToClaim, setTokensToClaim]= useState(null);
  const [approving, setApproving] = useState(false);
  const [errorClaiming, setErrorClaiming]= useState(false)
  useEffect(async () => {
    if(tokensToClaim?.length && claim) {
      const _tokensToClaim = [...tokensToClaim];
      setTokensToClaim([]);
      await claim(_tokensToClaim);
    }
  },[tokensToClaim, claim])
  useEffect(async () => {
    if(mintingFloor) {
      await mintFloor();
      setMintingFloor(false);
    }
  },[mintingFloor])
  useEffect(async () => {
    if(approving) {
      await approve();
      setApproving(false);
    }
  },[approving])
  useEffect(()=>{
    if(!isLoading) {
      unityContent.send("WebGLCommunicator", "SetMute", isMuted ? 0 : 1)
    }
  }, [isMuted]);
  useEffect(()=>{
    unityContent.on("GameStarted", () => {
      console.log('game started')
      unityContent.send("WebGLEntryController", "LoadScene", 3);
    });
    unityContent.on("SceneLoaded", () => {
      console.log('scene loaded');
      setIsLoading(false);
    });
    unityContent.on("progress", (progression) => {

      // Now we can use the progression to for example
      // display it on our React app.
      setLoadingProgress(progression*100);
    });
    unityContent.on("SelectedTangos", async (ids) => {
      setTokensToClaim(ids);
    })
    unityContent.on("MintFloor", async () => {
      setMintingFloor(true);
    })
    unityContent.on("SetApproval", async () => {
      setApproving(true);
    })

    unityContent.on("RequestViewData", () => {
      setIsReadyForCustomization(true);
    });
    unityContent.remove = () =>{};
    return function cleanup() {
      window.location.reload()
    }
  },[]);

  useEffect(()=>{
    if(tangos && isReadyForCustomization) {
      updateState(unityContent);
    }
  }, [tangos, isReadyForCustomization, floors])

  const settingsClicked = ()=>{
    let _quality = quality + 1;
    if(_quality> qualityValues.length -1) {
      _quality= 0;
    }
    setQuality(_quality);
  }
  
  useEffect(()=> {
    if(!isLoading){
      setQualityText(qualityMap[quality]);
      console.log("set quality", qualityValues[quality])
      unityContent.send("WebGLCommunicator", "SetQuality", qualityValues[quality]);
      const timeout = setTimeout(() => {
        setQualityText(null);
      }, 2500);
      return ()=> clearTimeout(timeout);
    }
  }, [quality])
  return (
    <>
      <div ref={wrapRef} className={"play-wrap"}>
        <LoadingScreen isLoading={isLoading} loadingProgress={loadingProgress}/>
        <div className="quality-text">{qualityText}</div>
        {!isLoading && 
          <div className="buttons">
            <Settings size={"40px"} onClick={()=>{settingsClicked(true)}}/>
            {(!isMuted) ? <VolumeX size={"40px"} onClick={()=>{setIsMuted(true)}}/> : <Volume2 size={"40px"} onClick={()=>setIsMuted(false)}/>}
          </div>
        }
        <Unity style={{ visibility: !isLoading ? "visible" : "hidden" }} unityContent={unityContent} />
      </div>
    </>
      
  );
}