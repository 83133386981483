import './styles.css';
import SingleViewPlayer from '../single-view-player';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import { getMetaDataURI } from '../../App';
import { Spinner } from '../spinner';
/*const metaData = {
  PrimaryColorInd: 0,
  SecondaryColorInd: 2,
  Egg: 1,
  Square: .1,
  Shininess: .1,
  Pattern: 0,
  PatternSize: .1,
  SparkeAmt: .01,
  MetallicAmt: .1,
  Gradient: .4,
  Noise: .01
}*/

export const getMetaData = (contract) => async (id, force) => {
  const response = await fetch(await getMetaDataURI(id,contract) + `${force ? '?force=true' : ''}`);
  return response.json();
}

export function SingleView({getMetaDataFromApi}) {
  let { id } = useParams();
  const [metaData, setMetaData] = useState(null);
  useEffect(() => {
    const retrieve = async () => {
      setMetaData(await getMetaDataFromApi(id));
    }
    retrieve();
  }, [id]);

  return (
    <div>
      <h2>SWEETANGO#{id}</h2>
      {metaData ? (
        <div class="viewer">

          <div class="game"> 
            <SingleViewPlayer metaData={metaData}></SingleViewPlayer>
          </div>
        </div>) : <div className="spinner-wrap"> <Spinner/></div>}

    </div>
  );
}

