import "./styles.css";
import wireframe from "../../images/sweeGrid.gif"
import { useState } from "react";
export const LoadingScreen = ({isLoading, loadingProgress})=>{
  const [loadingScreenReady, setLoadingScreenReady] = useState(false);
  return (
    <>
      {isLoading && (
        <div className={"loading-screen"}>
          <img onLoad={()=>setLoadingScreenReady(true)} src={wireframe}></img>
          {loadingScreenReady &&
          <>
            <div className="loading-text">...Loading</div>
            <div className="loading-bar">
              <div class="border">
                <div class="bar" style={{height:"24px", width:`${loadingProgress}%`}}></div>
              </div>
            </div>
          </>}
        </div>
      )}
    </>
  )
}