import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import './styles.css'
import { Card } from "../card";
import { CONTRACT_ABI } from '../../const';
import { BigNumber, ethers, utils } from "ethers";
import scanning from '../../images/scanning.gif'; 
import tube from '../../images/sweetube.mp4'
import example from '../../images/example.png'
import { Spinner } from "../spinner";
import drone from "../../images/drone.png";
import {X} from "react-feather";
import { useIsMobile } from "../../hooks/useIsMobile";
import swapper from "../../images/TangSwapper2.gif";

export function Minter({mint, updateBalances, totalSupply, freeMint, freeBalace, contractWrite, address, getMetaDataById, connect, error}) {

  const [numberToMint, setNumberToMint] = useState(1);
  const [tokensMinted, setTokensMinted] = useState([]);
  const [minting, setMinting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [cardReadyArr, setCardReadyArr] = useState([])
  const history = useHistory();
  const isMobile = useIsMobile();
  const amounts = [1,2,5];
  const tryMinting = async (free) => {
    try {
      setErrorMessage(null);
      setMinting(true);
      await callMint(free);
    } catch (err) {
      console.log('caught error')
      console.log(err);
    }
  }
  const callMint = async (free) => {
    const tokenIds = [];
    try {
      const res = free ? await freeMint() : await mint(numberToMint);
      const receipt = await res.wait(2);
      const tokenIds = receipt.logs.map(({topics}) => BigNumber.from(topics[3]).toNumber())
      const tokens = (await Promise.all(tokenIds.map((id)=>getMetaDataById(id, true)))).map((metaData)=>({id:metaData.id, metaData: metaData}))
      updateBalances();
      setTokensMinted(tokens);
    } catch (err) {

      if(tokenIds.length > 0) {
        try{
          const tokens = (await Promise.all(tokenIds.map((id)=>getMetaDataById(id, true)))).map((metaData)=>({id:metaData.id, metaData: metaData}));
          setTokensMinted(tokens);
          updateBalances();
          setMinting(false);
          return;
        } catch(err) {
          updateBalances();
          setErrorMessage("Can't display new tokens yet sorry! Go Your Collection Page to check them out");
          setMinting(false);
          return;
        }

      }
      console.log(err);
      console.log(err.code)
      if(err.code === 4001) {
        setErrorMessage('You denied the transaction!');
        setMinting(false);
        return;
      }
      const message = err?.data?.message;
      console.error(err);
      setErrorMessage(message ? err.data.message : 'Problem Minting! send dvd a screen shot of ur console please');
      setMinting(false);
    }
  }
  console.log(!tokensMinted.length);
  return (
  <div>
    {(!tokensMinted.length || cardReadyArr.length !== tokensMinted.length) && (
      <div class="mint-wrap">
        <div className="mint-header">
          {isMobile ? (
            <>
              <div className="image-wrap-mobile">
                <img src={swapper}></img>
              </div>
            </>
          ) : (
            <>
              <div className="video-wrap">
                <video src={tube} autoPlay loop muted/>
              </div>
              <div className="image-wrap">
                <img src={swapper}></img>
              </div>
            </>
          )}
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {(!minting && error === 0) &&
          (freeBalace > 0 ? (
            <div className="mint-buttons">
              <button disabled={!numberToMint} onClick={()=>tryMinting(true)} className="free-mint">Claim Your Free Sweetango</button>
            </div>
          ) : (
            <div className="mint-buttons">
              <button disabled={!numberToMint} onClick={()=>tryMinting(false)} className="mint">Mint</button>
              {amounts.map((amount) =>
                <button onClick={()=>setNumberToMint(amount)} className={`amount ${numberToMint===amount ? 'selected' : ''}`}>x{amount}</button>
              )}
            </div>
          ))

        }
        {(error>0 || !contractWrite) && (
          <div className={"connect-button"} onClick={connect}> Connect To FTM</div>
        )}
        {(!minting && error === 0) &&
          <>
            <div className={"mint-stats"}>
              <div className="stats-text">{totalSupply}<span>/</span>2345 minted!</div>
            </div>
            <div className={"price"}>80 FTM/ea !</div>
          </>
        }
        
        {minting && <Spinner></Spinner>}
      </div>
    )}


    {
      <div className={`minted ${cardReadyArr.length === tokensMinted.length ? ' slide-in' : ''}`}>
        {!!tokensMinted.length && <div className="minted-title"><div>Awesome! Check out your new little ones</div><X onClick={()=>{setCardReadyArr([]); setTokensMinted([]); setMinting(false);}} size="36px"/></div>}
        <div className={'minted-cards'}>
          {tokensMinted.map(token => (
            <Card size={isMobile ? 'small' : 'medium'} click={()=>{history.push(`/view/${token.id}`)}}ready={()=> setCardReadyArr(oldArr=> [...oldArr, true])} rotation={false} token={token}></Card>
          ))}
        </div>

      </div>
    }
    <div class={`intro ${isMobile ? 'mobile': ''}`}>
      <div class="header">
        <img src={drone}></img>
        <span>SweetTango Delivery System</span>
      </div>
      <div class="description">
        There’s currently 2345 genetically unique SweeTangos to be minted. After you mint your own SweeTango, we’ll air-mail it to the Garden where it will live out the rest of its days in perfect cryptographic harmony amongst the other 2344 minted Swee’s. Did we mention that your SweeTango will live forever?? Wow! We hope you enjoy your new pet, dont forget to come back and play with it because forever is a long time!
      </div>
    </div>
  </div>

  )

}