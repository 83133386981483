import './styles.css';
import { useEffect, useRef, useState } from "react";
import Unity, { UnityContent} from "react-unity-webgl";
import {
  Link, useHistory
} from "react-router-dom";
import { Volume2, VolumeX, Settings } from 'react-feather';
import { LoadingScreen } from '../loading-screen';
const unityContent = new UnityContent(
  "../build/Build/webGLBuild.json",
  "../build/Build/UnityLoader.js"
)
const qualityValues = [5, 3, 0];
const qualityMap = {0: 'High', 1: 'Medium', 2:'Low'}
export function GardenPlayer({getOwnerId}) {
  const [isMuted, setIsMuted] = useState(true);
  const [isLoading, setIsLoading] = useState(true, []);
  const [clickCordinates, setClickCordinates] = useState(null);
  const [clickedId, setClickedId] = useState(null)
  const [qualityText, setQualityText] = useState(null);
  const [quality, setQuality] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const wrapRef = useRef(null);
  useEffect(()=>{
    if(!isLoading) {
      unityContent.send("WebGLCommunicator", "SetMute", isMuted ? 0 : 1)
    }
  }, [isMuted]);
  const playerClicked = (event)=>{
    console.log('player clicked')
    console.log(event);
    const width = window.innerWidth/2;

    const {pageX: x , pageY: y} = event;
    setClickCordinates({x: 100 + x + wrapRef.current.style.left,y: y+wrapRef.current.style.top});
  }
  const contextMenuClicked = (event)=>{
    event.stopPropagation();
  }
  const [ownerId, setOwnerId] = useState(null)
  const history = useHistory();
  useEffect(async ()=> (clickedId===0 || clickedId) ? setOwnerId(await getOwnerId(clickedId)) : null, [clickedId])
  window.unityInstance = unityContent;
  useEffect(()=>{
    unityContent.on("GameStarted", () => {
      unityContent.send("WebGLEntryController", "LoadScene", 1);
    });
    unityContent.on("SceneLoaded", () => {
      setIsLoading(false);
    });
    unityContent.on("TangoClicked", (id) => {
      if(id!== clickedId){
        setOwnerId(null);
        setClickCordinates(null);
      }
      id===-1 ? setClickedId(null) : setClickedId(id);

    });
    unityContent.on("progress", (progression) => {
      // Now we can use the progression to for example
      // display it on our React app.
      setLoadingProgress(progression*100);
    });
    unityContent.remove = () =>{};
    return function cleanup() {
      window.location.reload()
    }
  },[]);
  const settingsClicked = ()=>{
    let _quality = quality + 1;
    if(_quality> qualityValues.length -1) {
      _quality= 0;
    }
    setQuality(_quality);
  }
  
  useEffect(()=> {
    if(!isLoading){
      setQualityText(qualityMap[quality]);
      console.log("set quality", qualityValues[quality])
      unityContent.send("WebGLCommunicator", "SetQuality", qualityValues[quality]);
      const timeout = setTimeout(() => {
        setQualityText(null);
      }, 2500);
      return ()=> clearTimeout(timeout);
    }
  }, [quality])
  const style = (clickCordinates && (clickedId || clickedId ===0)) ? {top: parseInt(clickCordinates.y), left: parseInt(clickCordinates.x)}: {display:"none"};
  const getDisplayAddress = (address) => address?.substring(0,37) + "..."
  return (
    <>
      <div ref={wrapRef} className={"play-wrap"} onClick={playerClicked}>
        <LoadingScreen isLoading={isLoading} loadingProgress={loadingProgress}/>
        <div className="quality-text">{qualityText}</div>
        {!isLoading && 
          <div className="buttons">
            <Settings size={"40px"} onClick={()=>{settingsClicked(true)}}/>
            {(!isMuted) ? <VolumeX size={"40px"} onClick={()=>{setIsMuted(true)}}/> : <Volume2 size={"40px"} onClick={()=>setIsMuted(false)}/>}
          </div>
        }
        <Unity style={{ visibility: !isLoading ? "visible" : "hidden" }} unityContent={unityContent} />
      </div>
      {(
        <div onClick={contextMenuClicked} className={`context-menu ${(clickedId === 0 || clickedId) ? 'show' :  ''}`}>
          <div className="context-header">
            <div className="context-id">#{clickedId}</div>
            <div className="context-button" onClick={()=>window.open(`/view/${clickedId}`)}>view stats</div>
          </div>
          
          <div className="context-owner">
            <div className={"context-owner-title"}>OWNED BY</div>
            <div className={"context-owner-address"} onClick={()=>window.open(`/collection/${ownerId}`, '_blank')}>{ownerId ? getDisplayAddress(ownerId): ""}</div>
          </div>
        </div>
      )}
    </>
      
  );
}