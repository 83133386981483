import "./styles.css";
import {Card} from '../card'
import { useEffect, useMemo, useState, useCallback, } from 'react';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CircleChart } from '../circle-chart';
import {Attributes} from '../attributes';
import { useIsMobile } from "../../hooks/useIsMobile";
export const PlainView = ({getMetaData, getIsOwner}) =>{
  let { id } = useParams();
  const history = useHistory();
  const [metaData, setMetaData] = useState(null);
  const [imageReady, setImageReady] = useState(false);
  const isMobile = useIsMobile();
  const [isOwner, setIsOwner] = useState(null);
  const stats = useMemo(()=>{
    return metaData ? [.5,.3,.9,.2,.3,.5] : null
  },[metaData])
  const handleOnClick = useCallback((id) => history.push(`/play/${id}`), [history]);
  useEffect(() => {
    const retrieve = async () => {
      setMetaData(await getMetaData(id));
    }
    retrieve();
  }, [id]);
  useEffect(async ()=>{
    if(getIsOwner) setIsOwner(await getIsOwner(id))
  },[getIsOwner])
  return (
    <div className={'stats-wrap'}>
      {metaData ? (
        <div className="stats-card-wrap">
          <Card ready={()=>setImageReady(true)} token={{id, metaData}}/> 
          {imageReady && <span onClick={()=>handleOnClick(id)} className="view-button">Click to view!</span>}
          {imageReady && isOwner && !isMobile &&
            <div className={"games-list"}>
              <div className={"games-list-title"}>Play Games!</div>
              <div className={"play-bomber"} onClick={()=> history.push(`/bomb/${id}`)}>Play BomberSwee</div>
            </div>
          }
        </div>)
        : null}
      {metaData && imageReady ? <Attributes attributes={metaData.attributes}/> : null}
    </div>
  )
}