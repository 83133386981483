import './styles.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useRouteMatch,
  NavLink
} from "react-router-dom";
import { useMemo, useState } from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Menu, X } from "react-feather";

export const NavBar = ({address, tryConnecting, balance})=>{
  const location = useLocation()
  const isMobile = useIsMobile()
  console.log('balance...',balance)
  const home = useRouteMatch({path: "/", exact:"true"});
  const collection = useRouteMatch("/collection/:address");
  const hn = home ? " home" : ""
  const cn = collection ? " coll-bg": ""
  const rot = useMemo(()=> ((Math.random())*5 + 3) * (Math.random()*2 > 1 ? -1 : 1), [location]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return !isMobile ? (
      <div className={"nav-bar" + hn + cn}>
        <NavLink exact activeClassName="selected" to="/">Mint</NavLink>
        <NavLink activeClassName="selected" to={`/collection/${address || ''}`}>Your Collection</NavLink>
        <NavLink activeClassName="selected" to="/garden/">Garden</NavLink>
        <NavLink activeClassName="selected" to="/play-pen/">Play Pen</NavLink>
      </div>
    ): <div className={"mobile-nav-bar"}>
          <div className={`nav-menu ${isMenuOpen ? 'show' : ''}`}>
            <X className="close" size="40px" onClick={()=>setIsMenuOpen(false)}/>
            <div>
              <div className="menu-item">
                <NavLink onClick={()=>setIsMenuOpen(false)} exact activeClassName="selected" to="/">Mint</NavLink>
              </div>
              <div className="menu-item">
                <NavLink onClick={()=>setIsMenuOpen(false)}  activeClassName="selected" to={`/collection/${address ?? ''}`}>Your Collection</NavLink>
              </div>
            </div>
          </div>
      {!isMenuOpen &&
        <div className="bar">
          <Menu size="40px" onClick={()=>setIsMenuOpen(true)}></Menu>
        </div>

      }
    </div>
}