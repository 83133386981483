import { BigNumber } from "ethers";
const parseScores = (scores) => scores.map(score=> (
  {
    address: score.scoreOwner,
    userName: score.message,
    score: BigNumber.from(score.score).toNumber(),
    confirmed: score.confirmed,
    id: score.id,
  }
)).sort((a,b)=> b.score - a.score)
.filter(({confirmed})=> confirmed);


export const getAllScores = contract => async () => {
  const scoreCount = await contract.scoreCount();
  const groupsOfHundreds = Math.floor(scoreCount/100);
  const extras = scoreCount - (groupsOfHundreds * 100);
  const scores = []
  for(var i = 0; i<groupsOfHundreds; i++) {
    scores.push(contract.getScores(100*i, 100));
  }
  if(extras>0){
    scores.push(contract.getScores(groupsOfHundreds*100, extras));
  }
  return parseScores((await Promise.all(scores)).flat());
}

export const hasSubmittedScore = contract => async (id) => {
  const res= await contract.hasSubmittedScore(id);
  return res;
}
export const submitScore = contract => setHasSubmittedScore => async ({score, username, hash, tangoId}) => { 
  try {
    let name = username;
    let id = tangoId;
    const res= await contract.storeScore(score, name, hash, id);
    const receipt = await res.wait(2);
    setHasSubmittedScore(await contract.hasSubmittedScore(id));
  } catch (err) { 
    console.log(err);
  }
}