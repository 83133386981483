import { utils, ethers } from 'ethers'
export const getStakedTangos = (farmContract) => async (address) => {
  const _stakedTangos = [];
  var staked = (await farmContract.farm(0))
  var i = 1;
  do {
    if(staked.owner === address){
      _stakedTangos.push(staked.tokenId);
    }
    staked = (await farmContract.farm(i++))
  } while (staked.owner !== '0x0000000000000000000000000000000000000000');
  return _stakedTangos;
}

export const getAdventureData = (questContract) => async (tangos) => {
  const cooldown = (await questContract.cooldown()).toNumber();
  new ethers.providers.Web3Provider(window.ethereum, "any");
  const data = tangos.map(async (Tango)=> {
    const timestamp = (await questContract.timeStampsByToken(Tango.Id)).toNumber();
    const TimeToReturn = (timestamp + cooldown);
    return {Tango, TimeToReturn}
  });
  return Promise.all(data);
}