import { primaryColors, secondaryColors } from "../../const";
import { useIsMobile } from "../../hooks/useIsMobile";
import "./styles.css";
export const Attributes= ({attributes}) =>{
  const isMobile = useIsMobile();
  const getBar = (number) => (
    <div className="attribute-bar-border">
      <div className="attribute-bar" style={{height:"100%", width:`${number*100 > 100 ? 100 : number*100}%`}}></div>
    </div>
  )

  const renderAttributeValue = (value) =>{
    if(typeof value=== 'number'){
      return getBar(value)
    } else if(primaryColors[value] || secondaryColors[value]){
      const color = primaryColors[value] || secondaryColors[value];
      return <div className="attribute-color-box" style={{backgroundColor: colorToString(color)}}></div>
    } else {
      return <div className="attribute-value">{value}</div>
    }
  }
  return <div className={`attributes ${isMobile ? 'mobile' : ''}`}>
    {attributes.map(({trait_type, value})=>
      <div className={"attribute"}>
        <div className="attribute-name">{trait_type}:</div>
        {renderAttributeValue(value)}
      </div>
    )}
  </div>
}

const colorToString = ({r,g,b}) => `rgb(${r * 255}, ${g*255}, ${b*255})`