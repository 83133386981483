import { useState } from 'react'
import './styles.css'
export function Card({token, click=()=>null, size= 'medium', rotation= true, ready=()=>null}){
  const [imageLoaded, setImageLoaded] = useState(false);
  let className = 'card '
  className += imageLoaded ? '' : 'loading-card ';
  className += size == 'small' ? 'small' : 'medium';
  className += token.staked ? ' staked' : ''
  const rot = rotation ?  (Math.random()*3) + 1 : 0;
  return (
    <div className={className} onClick={()=> click(token.id)}>
      <img style={{transform: `rotate(${rot}deg)`}} src={token.metaData.image} onLoad={()=>{ setImageLoaded(true); ready();}}></img>
    </div>
  )
}