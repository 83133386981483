import './styles.css';
import { useEffect, useState } from "react"
import { GardenPlayer } from "../garden-player";
import { Card } from "../card";
const NUMBER_OF_TANGOS = 5
const getRandomMetaDatas = (getMetaDataById, range, number) => {
  const array = [...Array(range).keys()]
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array.slice(0,number).map((tokenId) => getMetaDataById(tokenId))
}

export function GardenView({getOwnerId, totalSupply}) {
  return ( 
    <div>
      <div className="player">
        <GardenPlayer getOwnerId={getOwnerId} />
      </div>
    </div> 
  )
}