import './styles.css';
import { useMemo } from 'react';
import { PlayPenPlayer } from '../play-pen-player';
import { useEffect, useState } from 'react';
import { metaDataToUnityData } from "../../utils/dataTransforms";
import { utils, ethers, BigNumber } from 'ethers'
import { getAdventureData, getStakedTangos } from '../../utils/farm-utils';
import { isAllowed } from '../../utils/token-utils';
const biggestNumber = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
export function PlayPenView({getTokensForAddress, userAddress, tokenContract, questContract, nftContract, error, tryConnecting, floorContract, getFloorsForAddress}) {
  const [tangos, setTangos] = useState(null);
  const [fetching, setFetching] = useState(false)
  const [appleBalance, setAppleBalance] = useState(0);
  const provider = useMemo(()=> new ethers.providers.Web3Provider(window.ethereum, "any"), [])
  const [errMessage, setErrMessage] = useState(0);
  const [applesAllowed, setApplesAllowed] = useState(null);
  const [costOfFloor, setCostOfFloor] = useState(0);
  const [floors, setFloors] = useState([]);
  const [odds, setOdds] = useState(null);
  const claim = async (adventurers) => {
    try {
      const res = await questContract.claim(adventurers);
      await res.wait(2);
      setErrMessage(0);
    } catch(err) {
      setErrMessage(1);
      return
    }
    try {
      await updateApples();
      await updateTangos();
    } catch (err) {
      setErrMessage(2);
    }

  }

  useEffect( ()=>{
    if(userAddress && getTokensForAddress && !fetching){
      setFetching(true);
      updateTangos();
      updateFloors();
    }
  }, [userAddress, getTokensForAddress])

  useEffect(async ()=>{
    if(error == 0 && tokenContract && floorContract){
      updateApples();
      updateIsAllowed();
      updateCostOfFloor();
    } 
  }, [error, tokenContract, floorContract])

  const updateTangos = async () => {
    try {
      const unstakedTangos = (await getTokensForAddress(userAddress)).map(({metaData})=> metaDataToUnityData(metaData));
      const _tangos = await getAdventureData(questContract)(unstakedTangos);
      setTangos(_tangos);
    } catch (err) {
      setErrMessage(2)
    }
  }

  const updateFloors = async () => {
    setFloors((await getFloorsForAddress(userAddress)).map(({metaData})=>metaData));
    console.log(await floorContract.totalSupply());
  }
  const updateApples = async () => {
    try{
      const _appleBanlance = (await tokenContract.balanceOf(userAddress))
      setAppleBalance(utils.formatEther(_appleBanlance));
    } catch (err) {
      setErrMessage(2)
    }
  };
  const updateIsAllowed = async () => {
    console.log("updating", await isAllowed(tokenContract)(userAddress)(floorContract.address));
    setApplesAllowed(await isAllowed(tokenContract)(userAddress)(floorContract.address));
  }
  const approve = async () => {
    try{ 
      console.log('approving');
      const res = await tokenContract.approve(floorContract.address, BigNumber.from(biggestNumber));
      await res.wait(2);
    } catch (err) {
      setErrMessage(1);
    }
    console.log('success aproving');
    setErrMessage(0);
    await updateIsAllowed();
    await updateTangos();
    return true;
  }
  const revoke = async () => {
    try{ 
      const res = await tokenContract.approve(floorContract.address, 0);
      await res.wait(2);
    } catch (err) {
      setErrMessage(1);
    }
    setErrMessage(0);
    await updateIsAllowed();
    await updateTangos();
    return true;
  }
  const updateState = (_unityContent) => {
    console.log("sendstate", {
      Tangos: tangos, 
      Apples: appleBalance, 
      ErrorCode: errMessage, 
      ApplesApproved: applesAllowed, 
      CostOfFloor: costOfFloor,
      FloorDTOs: floors,
      Odds: odds,
      });
    _unityContent.send("WebGLCommunicator", "SetViewData", JSON.stringify({
      Tangos: tangos, 
      Apples: appleBalance, 
      ErrorCode: errMessage, 
      ApplesApproved: applesAllowed, 
      CostOfFloor: costOfFloor,
      FloorDTOs: floors,
      Odds: odds,
    }));
    setErrMessage(0);
  }


  const mintFloor = async () => {
    try {
      const res = await floorContract.mintFloor();
      await res.wait(2);
      setErrMessage(0);
    } catch(err) {
      setErrMessage(1);
      await updateTangos()
      return
    }
    await Promise.all([updateCostOfFloor(), updateApples()]);
    await updateFloors();
  }
  const updateCostOfFloor = async () => {
    const cost = await floorContract.purchaseCostByAddress(userAddress);
    const _odds = await floorContract.getOdds(userAddress);
    const oddsBase = _odds[0].toNumber();
    setOdds({
      rareOdds: (_odds[1].toNumber()/oddsBase) * 100,
      legendaryOdds: (_odds[2].toNumber()/oddsBase) * 100,
    });
    setCostOfFloor(utils.formatEther(cost));
  }

  const giveApples = async () => {
    tokenContract.mint("0xE1e0f11F9F36c0dE0755412Fab08B7E5D754a274", utils.parseUnits("100000.0", "ether"));
  }
  return ( 
    <div>
      {!!error && <div className="connect-button" onClick={tryConnecting}>connect to ftm</div>}
      {!error && 
        <div className="player">
          <PlayPenPlayer 
            claim={claim} 
            tangos={tangos} 
            updateState={updateState}
            mintFloor={mintFloor}
            approve={approve}
            floors={floors}
          />
        </div>
      }
    </div> 
  )
}