import './App.css';
import { BigNumber, ethers, utils } from "ethers";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import { Home } from './components/home';
import { Chat } from './components/chat'
import { Collection } from './components/collection';
import { SingleView, getMetaData} from './components/single-view'
import { PlainView} from './components/plain-view';
import { useEffect, useMemo, useState } from 'react';
import { CONTRACT_ABI, FARM_ABI, FLOOR_ABI, LEADERBOARD_ABI, LEADERBOARD_CONTRACT_ADDRESS, QUEST_ABI, PROD_CONFIG, TEST_CONFIG, TOKEN_ABI } from './const';
import { GardenView } from './components/garden';
import { BombView } from './components/bomb-view';
import { NavBar } from './components/nav-bar';
import { AboutPage } from './components/about';
import { ConnectModal } from './components/connect-modal';
import { API_URL } from './const';
import { PlayPenView } from './components/play-pen';
import { getStakedTangos } from './utils/farm-utils';
const metaData = {
  PrimaryColorInd: 0,
  SecondaryColorInd: 2,
  Egg: 1,
  Square: .1,
  Shininess: .1,
  Pattern: 0,
  PatternSize: .1,
  SparkeAmt: .01,
  MetallicAmt: .1,
  Gradient: .4,
  Noise: .01
}

const CONFIG = PROD_CONFIG;
export const getMetaDataURI = async (index, contract) => {
  return (contract.tokenURI(index))
}
export const getMetaDatasForAddress = (contract) => async (address) => {
  const numberOf = (await contract.balanceOf(address)).toNumber();
  const tokens = []
  const tokenIds = Array.from(Array(numberOf), () => address)
    .map((address, index) => getTokenId(address,index,contract)
      .then(async (id) => {
        const metaData = await getMetaData(contract)(id);
        tokens.push({metaData, id});
    }));
  await Promise.all(tokenIds);
  return tokens.sort(({id:a},{id:b}) => a-b);
}
const getStakedMetaDatasForAddress = (farmContract) => (contract) => async address =>{
  const tokenIds = await getStakedTangos(farmContract)(address);
  const tokenPromises = tokenIds.map(async (id)=>{
    const metaData = await getMetaData(contract)(id);
    return {metaData, id};
  })
  const tokens = await Promise.all(tokenPromises);
  return tokens.sort(({id:a},{id:b}) => a-b);
}

export const getMetaDataFromApi = async (tokenId) => {
  const response = await fetch(`${API_URL}cuties/${tokenId}`); 
  return response.json();
}

export const isOwner = contract => address => async id => {
  return (await contract.ownerOf(id)) === address;
}
const getTokenId = async (owner, index, contract) => (await contract.tokenOfOwnerByIndex(owner, index)).toNumber()
function App() {
  const [provider, setProvider] = useState(()=> window.ethereum ? new ethers.providers.Web3Provider(window.ethereum, "any"): null, []);
  const [address, setAddress] = useState(null);
  const contract = useMemo(() => provider ? new ethers.Contract(CONFIG.contracts.nft, CONTRACT_ABI, provider) : null)
  const [tokenCost, setTokenCost] = useState(0);
  const [error, setError] = useState(1);
  const [contractWrite, setContractWrite]= useState(null);
  const [leaderBoardContract, setLeaderBoardContract]= useState(null); 
  const [totalSupply, setTotalSupply] = useState(null); 
  const [balanceOf, setBalanceOf] = useState(null); 
  const [freeBalance, setFreeBalance] = useState(null);
  useEffect(async () => setFreeBalance(contractWrite ? await contract.freeBalance(address): null), [contractWrite]);
  useEffect(async () => setTokenCost(contractWrite ? await contract.cutiePrice(): null), [contractWrite])
  useEffect(async () => setBalanceOf((contractWrite ? (await contract.balanceOf(address)).toNumber() : null)), [contractWrite])
  useEffect(async () => setTotalSupply(contractWrite ? (await contract.totalSupply()).toNumber() : null), [contractWrite])
  useEffect(async ()=> setLeaderBoardContract(new ethers.Contract(LEADERBOARD_CONTRACT_ADDRESS, LEADERBOARD_ABI, await provider.getSigner())), [error, address])
  
  const [tokenContract, setTokenContract]= useState(null); 
  useEffect(async ()=> setTokenContract(new ethers.Contract(CONFIG.contracts.token, TOKEN_ABI, await provider.getSigner())), [error, address])
  const [farmContract, setFarmContract]= useState(null); 
  //useEffect(async ()=> setFarmContract(new ethers.Contract(CONFIG.contracts.farm, FARM_ABI, await provider.getSigner())), [error, address])
  const [questContract, setQuestContract]= useState(null);
  useEffect(async ()=> setQuestContract(new ethers.Contract(CONFIG.contracts.quest, QUEST_ABI, await provider.getSigner())), [error, address])
  const [floorContract, setFloorContract]= useState(null);
  useEffect(async ()=> setFloorContract(new ethers.Contract(CONFIG.contracts.floor, FLOOR_ABI, await provider.getSigner())), [error, address])
  useEffect(async () => {
   try {
      window.ethereum?.on("chainChanged", (chainId) => {
        if(chainId !== CONFIG.chainId) {
          return setError(1);
        } else {
          setError(0);
        }
      });
      window.ethereum?.on("accountsChanged", (accounts) => {
        if(accounts.length === 0){
          setError(1);
        }
      });
      const chainId = await checkChainId();
      if(chainId !== CONFIG.chainId) {
        return setError(1);
      }
      const accounts = await provider.listAccounts();
      if(accounts.length) {
        setAddress(accounts[0]);
        setContractWrite(new ethers.Contract(CONFIG.contracts.nft, CONTRACT_ABI, await provider.getSigner()))
        setError(0)
      } else {
        setError(1)
      }
    } catch (err) {
      setError(1)
    }

  },[provider])
  const tryConnecting = async () => {
    // Prompt user for account connections
    await provider.send("eth_requestAccounts", []);
    const chainId = await checkChainId();
    if(chainId !== CONFIG.chainId) {
      try {
        await switchChains();
      } catch (err) {
        console.log('error 115', err);
        return setError(1);
      }
    }
    
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    setAddress(await signer.getAddress());
    setContractWrite(new ethers.Contract(CONFIG.contracts.nft, CONTRACT_ABI, signer))
    setError(0)
  }

  const switchChains = async ()=> {
    await provider.send('wallet_addEthereumChain', CONFIG.addChain);
    await provider.send('wallet_switchEthereumChain', [{chainId: CONFIG.chainId}]);
  }

  const getOwnerAddress = (contract) => async (tokenId) => {
    return contract?.ownerOf(tokenId);
  } 

  const checkChainId = async () => {
    return await provider.send('eth_chainId');
  }
  const updateBalances = async ()=> {
    setFreeBalance(address ? await contract.freeBalance(address): null);
    setTokenCost(address ? await contract.cutiePrice(): null);
  }
  const mint = (numberToMint) => {
    return contractWrite.mintCutie(numberToMint, {value: tokenCost.mul(numberToMint)}) 
  }
  const freeMint = () => {
    return contractWrite.claimFree() 
  }
  const connectModal = <ConnectModal noProvider={(!provider)} onClick={tryConnecting}/>
  return (
    <Router>
        <NavBar balance={balanceOf} address={address}  tryConnecting={tryConnecting}/>
        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/">
            <Home error={error} 
              totalSupply={totalSupply}
              connect={tryConnecting} 
              noProvider={!provider} 
              getMetaDataById={getMetaData(contract)} 
              contract={contract} 
              address={address} 
              mint={mint} 
              freeMint={freeMint}
              freeBalance={freeBalance}
              updateBalances={updateBalances}
              contractWrite={contractWrite}
            />
          </Route>
          <Route path="/play/:id/">
            <SingleView getMetaDataFromApi={getMetaDataFromApi}/>
          </Route>
          <Route path="/view/:id/">
            <PlainView getMetaData={!error ? getMetaData(contract) : getMetaDataFromApi} getIsOwner={address ? isOwner(contract)(address) : null}/>
          </Route>
          <Route path="/bomb/:id/">
            <BombView isOwner={isOwner(contract)(address)} address={address} error={error} tryConnecting={tryConnecting} getMetaData={getMetaData(contract)} leaderBoardContract={leaderBoardContract} />
          </Route>
          <Route path="/garden/">
            {<GardenView getOwnerId={getOwnerAddress(contract)} getMetaDataById={getMetaData(contract)} totalSupply={totalSupply} />}
          </Route>
          <Route path="/play-pen/">
            {<PlayPenView 
              tryConnecting={tryConnecting}
              error={error}
              nftContract={contractWrite}
              getTokensForAddress={contractWrite ? getMetaDatasForAddress(contractWrite) : null} 
              getFloorsForAddress={contractWrite ? getMetaDatasForAddress(floorContract) : null} 
              userAddress={address}
              tokenContract={tokenContract}
              questContract={questContract}
              floorContract= {floorContract}
            />}
          </Route>
          <Route path="/collection/:address?">
            { <Collection 
                tryConnecting={tryConnecting}
                error={error} 
                getTokensForAddress={getMetaDatasForAddress(contractWrite)}
                getStakedTokensForAddress={getStakedMetaDatasForAddress(farmContract)(contractWrite)}
                userAddress={address} 
                contractWrite={contractWrite} 
              />}
          </Route>
        </Switch>
    </Router>
  );
}

export default App;
