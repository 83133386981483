import { useEffect } from "react";
import { useMemo, useState } from "react";
import { ArrowLeft, ArrowRight} from "react-feather";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAllScores } from "../../utils/score-utils";
import './styles.css';
const getChunks = (input, perChunk)=>{
  if(!input) return [];
  return input.reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index/perChunk)
  
    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }
  
    resultArray[chunkIndex].push(item)
  
    return resultArray
  }, [])
}

export const Leaderboard = ({contract, scoreSubmitted}) => {
  const history = useHistory();
  const pageSize = 10;
  const [scores, setScores] = useState(null);
  const scorePages = useMemo(()=>getChunks(scores, pageSize), [scores]);
  const [pageNumber, setPageNumber] = useState(0);
  const updatePageNumber = (p)=>{
    setPageNumber(pageNumber+p);
  }
  
  useEffect(async ()=>{
    if(contract && (scoreSubmitted || scoreSubmitted===false)){
      console.log('getting scores...')
      setScores(await getAllScores(contract)())
    }

  },[contract, scoreSubmitted])
  console.log(scores);
  return (
    <div>
      <div>
        {scores &&
          <div className="table">
            <table>
              <tbody>
                <tr>
                  <th>Rank</th>
                  <th>ID</th>
                  <th>Username</th>
                  <th>Score</th>
                </tr>
                {scorePages[pageNumber]?.map(({score, userName, id}, index)=>(
                  <tr className={false ? "yours" : ""}>
                    <td>{index + pageNumber*pageSize}</td>
                    <td><div onClick={()=>{history.push(`/view/${id}`)}}>{`Sweetango #${id}`}</div></td>
                    <td>{userName}</td>
                    <td>{score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="paginator">
              <div>
                <ArrowLeft size={'50px'} disabled={pageNumber===0} onClick={()=>updatePageNumber(-1)}/>
              </div>
              <div>
                <ArrowRight size={'50px'} disabled={pageNumber>=scorePages.length-1} onClick={()=>updatePageNumber(1)}/>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}