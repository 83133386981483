import { useCallback, useEffect, useState } from "react";
import {
  Link, useHistory
} from "react-router-dom";
import './styles.css';
import { Card } from '../card'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Spinner } from "../spinner";
import { useIsMobile } from "../../hooks/useIsMobile";
const getChunks = (input, perChunk)=>{
  return input.reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index/perChunk)
  
    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }
  
    resultArray[chunkIndex].push(item)
  
    return resultArray
  }, [])
}
export function Collection({getTokensForAddress, getStakedTokensForAddress, userAddress, tryConnecting, error, contractWrite}) {
  const history = useHistory();
  const handleOnClick = useCallback((id) => history.push(`/view/${id}`), [history]);
  const { address } = useParams();
  const [tokens, setTokens] = useState(null);
  const [stakedTokens, setStakedTokens] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useIsMobile();
  const [isValidAddress, setIsValidAddress] = useState(true);
  console.log(error);
  useEffect(()=> {
    if(!address && userAddress)
    history.push('/collection/'+ userAddress)
  }, [address, userAddress])
  useEffect(async ()=> {
    console.log(address);
    if(address && !error && contractWrite) {
      try { 
        const _tangos = await getTokensForAddress(address)
        setTokens([..._tangos])
      } catch (err) {
        console.log(err);
        setIsValidAddress(false);
      }
      setIsLoading(false)
    }
  }, [address, error, contractWrite])
  const chunks = tokens ? getChunks(tokens, isMobile ? 1 : 5) : null;
  const stakedChunks = stakedTokens ? getChunks(stakedTokens, isMobile ? 1 : 5) : null;
  return (
    <div className="garden-background">
      {!userAddress && error && <div className="connect-button" onClick={tryConnecting}>connect to ftm</div>}
      {isLoading && userAddress && <Spinner/>}
      {!isLoading && isValidAddress && !tokens?.length && <div className={'error-collection'}>No tokens here</div>}
      {!isValidAddress && !error && <div className={'error-collection'}>Invalid Address </div>}
      {chunks ? 
        chunks.map((chunk, index) => (
          <div id={index} className="collection">
            {chunk.map(token => (
              <Card id={token.id} click={handleOnClick} token={token}></Card>
            ))}
          </div>
        )) :
        null }
    </div>
  );
}
