export const metaDataToUnityData = (metaData) => {
  const unityData =  {
    Id: metaData.id,
    ImgHash: "",
    ...dataFromAttributes(metaData)
  }
  return unityData;
}

const dataFromAttributes = ({attributes}) => {
  const data = {}
  attributes.forEach((attribute) =>{
    if(!attribute.rare){
      const attributeName = Object.keys(AttributeNameList).find((key)=> AttributeNameList[key] === attribute.trait_type)
      data[attributeName] = attribute.value;
    }
  })
  const features = []
  attributes.filter(({rare})=> rare).forEach((attribute)=>{
    features.push(attribute.trait_type);
  })
  data['Features'] = features; 
  return data;
}
var AttributeNameList = {
  Egg: "Egg Head",
  Square: "Square Head",
  Shininess: "Shiny",
  PatternSize: "Pattern Size",
  SparkleAmt: "Sparkle Amount",
  MetallicAmt: "Metallic",
  SmoothnessAmt: "Smoothness",
  Noise: "Noise",
  Height: "Height",
  Weight: "Weight",
  PrimaryColor: "Primary Color",
  SecondaryColor: "Secondary Color",
  Eye: "Eye",
  Mouth: "Mouth",
  "Leaf": "Leaf",
  "PrimaryPattern": "Primary Pattern",
  "SecondaryPattern": "Secondary Pattern",
  "Sparkles": "Sparkle Texture",
  "LikedItem": "Favorite Item",
  "Pose":"Pose",
  "Border": "Border",
  "Zone": "Zone",
}
const createAttributesArray = (data) => {
  const attributes = []
  for(const attribute in AttributeNameList){
    attributes.push({
      trait_type: AttributeNameList[attribute],
      value: data[attribute] 
    })
  }
  data.Features.map((feature)=>{
    attributes.push({trait_type: feature, value: "Activated!", rare:true })
  })
  return attributes;
}
export const unityDataToMetaData = (data, baseUrl) => {
  const metaData = {
    image: baseUrl + data.ImgHash,
    name: `Sweetango #${data.Id}`,
    id: data.Id,
    desciption: "A beautiful Sweetango",
    external_url: `www.sweetango.zone/view/${data.Id}`,
    attributes: createAttributesArray(data),
  }
  return metaData;
}