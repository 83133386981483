import "./styles.css"
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import { BombPlayer } from "../bomb-player"
import { Leaderboard } from "../leaderboard";
import { submitScore, hasSubmittedScore } from "../../utils/score-utils";

export const BombView = ({address, isOwner, tryConnecting, getMetaData, error, leaderBoardContract}) => {
  const { id } = useParams();
  const [metaData, setMetaData] = useState(null);
  const [owner, setOwner] = useState(null);
  const [scoreSubmitted, setScoreSubmitted] = useState(null);
  useEffect(() => {
    const retrieve = async () => {
      if(error == 0){
        setMetaData(await getMetaData(id));
        setOwner(await isOwner(id))
        setScoreSubmitted(await hasSubmittedScore(leaderBoardContract)(id))
      } 
    }
    retrieve();
  }, [id, error]);
  console.log('bomb-view', scoreSubmitted);
  return (
    <div className={"bomb-wrap"}>
      {!!error && <div className="connect-button" onClick={tryConnecting}>connect to ftm</div>}
      {!error && owner && <BombPlayer submitScore={submitScore(leaderBoardContract)(setScoreSubmitted)} address={address} metaData={metaData} scoreSubmitted={scoreSubmitted}></BombPlayer>}
      {owner === false &&<div> this is not ur sweetango, hunny</div>}
      {!error && owner && <Leaderboard contract={leaderBoardContract} scoreSubmitted={scoreSubmitted}/>}
    </div>
  )

}