import { useEffect, useState } from "react";
import Unity, { UnityContent} from "react-unity-webgl";
import { metaDataToUnityData } from "../../utils/dataTransforms";
import { LoadingScreen } from "../loading-screen";
import { Volume2, VolumeX, Settings } from 'react-feather';
const unityContent = new UnityContent(
  "../build/Build/webGLBuild.json",
  "../build/Build/UnityLoader.js"
)
const qualityValues = [5, 3, 0];
const qualityMap = {0: 'High', 1: 'Medium', 2:'Low'}
function SingleViewPlayer({
  metaData
}) {
  const [isLoading, setIsLoading] = useState(true, []);
  const [loadingProgress, setLoadingProgress]= useState(0);
  const [readyForCustomization, setIsReadyForCustomization] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [qualityText, setQualityText] = useState(null);
  const [quality, setQuality] = useState(0);
  useEffect(()=>{
    if(!isLoading) {
      unityContent.send("WebGLCommunicator", "SetMute", isMuted ? 0 : 1)
    }
  }, [isMuted]);
  useEffect(()=>{
    unityContent.on("GameStarted", () => {
      unityContent.send("WebGLEntryController", "LoadScene", 2);
    });

    unityContent.on("SceneLoaded", () => {
      setIsLoading(false);
    });
    unityContent.on("RequestViewerCust", () => {
      console.log('request cust');
      setIsReadyForCustomization(true);
    });
    unityContent.on("progress", (progression) => {
      // Now we can use the progression to for example
      // display it on our React app.
      setLoadingProgress(progression*100);
    });
    unityContent.remove = () =>{};
    return function cleanup() {
      window.location.reload()
    }
  },[]);
  useEffect(()=>{
    if(metaData && readyForCustomization){
      unityContent.send("WebGLCommunicator", "SetCustomization", JSON.stringify(metaDataToUnityData(metaData)));
    }
  }, [metaData, readyForCustomization])
  useEffect(()=> {
    if(!isLoading){
      setQualityText(qualityMap[quality]);
      console.log("set quality", qualityValues[quality])
      unityContent.send("WebGLCommunicator", "SetQuality", qualityValues[quality]);
      const timeout = setTimeout(() => {
        setQualityText(null);
      }, 2500);
      return ()=> clearTimeout(timeout);
    }
  }, [quality])
  const settingsClicked = ()=>{
    let _quality = quality + 1;
    if(_quality> qualityValues.length -1) {
      _quality= 0;
    }
    setQuality(_quality);
  }

  return (
      <div className="single-view-player">
          <div className="quality-text">{qualityText}</div>
          <div className="buttons">
            <Settings size={"40px"} onClick={()=>{settingsClicked(true)}}/>
            {(!isMuted) ? <VolumeX size={"40px"} onClick={()=>{setIsMuted(true)}}/> : <Volume2 size={"40px"} onClick={()=>setIsMuted(false)}/>}
          </div>
          <LoadingScreen isLoading={isLoading} loadingProgress={loadingProgress}/>
          <Unity style={{ visibility: !isLoading ? "visible" : "hidden" }} unityContent={unityContent} />
      </div>

  );
}

export default SingleViewPlayer;